const dataArray =[
    {
        pageTitle: "Strategie",
    },
    {
        pageTitle: "Führung",
    },
    {
        pageTitle: "Struktur",
    },
    {
        pageTitle: "Prozess",
    },
    {
        pageTitle: "Technologie",
    },
    {
        pageTitle: "Unternehmenskultur",
    }
  ]

  export default dataArray;