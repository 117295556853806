import React from 'react';
import { textContainer } from './styles';

const DisplayReifegradResultsSmall = () => {
     return (
        <div css={textContainer}>
            <h2>
                Ihre Ergebnisse im  Detail
            </h2>
            <p>Tauchen Sie ein in Ihre Digitale Reifegradanalyse: Erhalten Sie detaillierte Einblicke!</p>
            <p>Sie haben den ersten Schritt gemacht, indem Sie Ihre digitale Reife bewerten. Doch was kommt als Nächstes? Ihre Ergebnisse sind nicht nur Zahlen auf einem Blatt Papier. Sie sind der Schlüssel zur Enthüllung einer Reise durch die digitale Transformation Ihres Unternehmens!</p>
            <p>Entdecken Sie <span css={{fontWeight: 600}}>Einblicke in Ihre Stärken und Schwächen</span>. Erfahren Sie, wo Ihr Unternehmen glänzt und wo es noch Raum für Wachstum gibt.</p>
            <p><span css={{fontWeight: 600}}>Vergleichen Sie sich mit Branchenführern:</span> Sehen Sie, wie Sie im Vergleich zu anderen Unternehmen in Ihrer Branche abschneiden und wo Sie sich positionieren können.</p>
            <p><span css={{fontWeight: 600}}>Maßgeschneiderte Empfehlungen:</span> Erhalten Sie personalisierte Handlungsempfehlungen, um Ihren digitalen Reifegrad zu steigern und Ihre Ziele zu erreichen.</p>
            <p><span css={{fontWeight: 600}}>Interaktive Visualisierungen:</span> Erkunden Sie Ihre Ergebnisse mit interaktiven Grafiken und Dashboards, die Ihnen helfen, Muster und Trends zu erkennen.</p>
            <p>Bereiten Sie sich darauf vor, Ihre digitale Agenda auf die nächste Stufe zu heben. Ihre Ergebnisse sind nicht nur Zahlen - sie sind der Schlüssel zu einer erfolgreichen digitalen Zukunft für Ihr Unternehmen!</p>
            <p>Melden Sie sich direkt bei mir unter <a href="tel:+497618976183">+49 (0) 761 - 897 61 83</a>, um Ihre detaillierten Ergebnisse zu erhalten und die Reise zu beginnen!</p>
            <p>Oder buchen Sie einen Online-Termin unter <a href="https://www.proceed.gmbh/kontakt">https://www.proceed.gmbh/kontakt</a></p>
        </div>
  )
}

export default DisplayReifegradResultsSmall