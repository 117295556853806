import { useQuery } from '@apollo/client'
import { Container, FlexBox, Wrapper } from '@components/layout'
import React, { useMemo } from 'react'
import { cloneDeep, get, set } from 'lodash';
import Img from "gatsby-image/withIEPolyfill";
import get_reifegrad from './constants/get_reifegrad'
import appStyles from '@appStyles'
import get_reifegrade from './constants/get_reifengrade'
import DisplayReifegradResultsChart from './content/DisplayReifegradResultsChart'
import form_data from '@content/Reifegrad/constants/form_data';
import DisplayReifegradResultsText from './content/DisplayReifegradResultsText';
import { graphql, useStaticQuery } from 'gatsby';
import Image from '@mainComponents/image';
import { imageContainer } from './styles';

const transformNumber = (number) => {
    const numb = parseFloat(number).toFixed(1)
  
    return parseFloat(numb)
  }

const ReifegradResults = ({id}) => {
    const {data} = useQuery(get_reifegrad, {variables: {id: id}, fetchPolicy: 'no-cache'})
    const {data: allResults} = useQuery(get_reifegrade, {fetchPolicy: 'no-cache'})
    const imageData = useStaticQuery(graphql`
    query {
          mk_small: file(relativePath: { eq: "mk_small.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 300, quality: 50) {
              ...GatsbyImageSharpFluid_tracedSVG
              }
          }
        }
      }
      `)

    const testResults = useMemo(() => {

        const allQuestions = []
        const allQuestionsGesamt = []
        const formDataTest = cloneDeep(form_data) 
        const formDataGesamt = cloneDeep(form_data) 

        if (data && allResults) {
            let testResultsObject = {...data.reifegrad}
            let allResultsObject = {}
          
            allResults.reifegrade.forEach(rg => {
              Object.keys(rg).forEach(rgKey => {
                if (typeof rg[rgKey] === 'number') {
                  const value = get(allResultsObject, rgKey, 0)
                  transformNumber(value + rg[rgKey]/allResults.reifegrade.length)
                  set(allResultsObject, rgKey, transformNumber(value + rg[rgKey]/allResults.reifegrade.length) )
                }
              })
            })
          
            form_data.forEach((formData, formDataIndex) => formData.questionsPerPage.forEach((questionPerPage, questionPerPageIndex) =>{
              // const sum = get(formData, `questionsPerPage[${questionPerPageIndex}].value`, 0);
              let sum = 0;
              let sumGesamt = 0;
          
              questionPerPage.questions.forEach((question, questionIndex) => {
                if (testResultsObject[question.name]) {
                  sum = sum + testResultsObject[question.name]
                  set(formDataTest, `${formDataIndex}.questionsPerPage[${questionPerPageIndex}].questions[${questionIndex}].value`,  testResultsObject[question.name] );
                  allQuestions.push({...question, value: testResultsObject[question.name]});
                }
                if (allResultsObject[question.name]) {
                  const value = allResultsObject[question.name];
                  sumGesamt = sumGesamt + value;
                  set(formDataGesamt, `${formDataIndex}.questionsPerPage[${questionPerPageIndex}].questions[${questionIndex}].value`,  value );
                  allQuestionsGesamt.push({...question, value});
                }
              })
                set(formDataTest, `${formDataIndex}.questionsPerPage[${questionPerPageIndex}].value`, sum );
                set(formDataGesamt, `${formDataIndex}.questionsPerPage[${questionPerPageIndex}].value`, transformNumber(sumGesamt) );
              }));
        }
          return ({formDataTest, formDataGesamt});
      },[data, allResults])
    
    return (
        <>
            <Wrapper background={appStyles.colors.background2} isTopHeader> 
                <Container>
                    <FlexBox direction='row' align='center' justify='flex-start' gap='60px'>
                        <FlexBox direction='column' align="flex-start" justify='center'>
                            <h1> Ergebnisse Reifegradmodell </h1>
                            <h3> Digitalisierung & Kultur </h3>
                        {data &&
                            <p css={{textAlign: "left", width: "90%", fontSize: "1.2rem", fontWeight: 500, marginBottom: 0}}>
                                {data.reifegrad.name} - {data.reifegrad.firma} - {data.reifegrad.email}
                            </p>
                            }
                        </FlexBox>
                        <div css={imageContainer}>
                            <Image image='labyrinth' />
                        </div>
                    </FlexBox>
                </Container>
            </Wrapper>
            <Wrapper> 
                <Container>
                    <FlexBox direction='column' align='stretch' gap={appStyles.sizes.extraLarge} styles={{'h2': {marginBottom: '2.4rem', marginTop: '2.4rem'}}}>
                        {data && allResults && data?.reifegrad?.abgeschlossen === true && <DisplayReifegradResultsChart testResults={testResults} />}
                        {data && allResults && data?.reifegrad?.abgeschlossen === true && <DisplayReifegradResultsText testResults={testResults} />}
                        {data && data?.reifegrad?.abgeschlossen !== true && <h3>Dieser Test ist noch nicht abgeschlossen</h3>}
                        <FlexBox direction='column' align='flex-start'>
                            <h2 >
                                Haben Sie Fragen zum Test?
                            </h2>
                            <FlexBox direction='row' align='center' gap={appStyles.sizes.extraLarge}>
                              <div css={{height: '8rem', width: '8rem', borderRadius: '1rem 0 1rem 0', overflow: 'hidden', boxShadow: `6px 6px 14px -8px ${appStyles.colors.dark}`, marginBottom: '1rem'}} >
                                  <Img 
                                  fluid={imageData.mk_small.childImageSharp.fluid}
                                  objectFit="cover"
                                  objectPosition="50% 50%"
                                  />
                              </div>
                                
                              <div> 
                                <h4> Michael Kirsch</h4>
                                <p>
                                  E-Mail: <a href="mailto:info@proceed.gmbh">info@proceed.gmbh</a> <br />
                                  Telefon: <a href="tel:+497618976183">0761 / 897 61 83</a> <br />
                                </p>
                                </div>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Container>
            </Wrapper>
        </>
    )
}

export default ReifegradResults