import appStyles from "@appStyles";

export const labelContainer = {
    padding: appStyles.padding.small,
    border: `1px solid ${appStyles.colors.darkgrey}`,
    backgroundColor: appStyles.colors.white,
    'p': {
        fontSize: '.9rem',
        marginBottom: 0,
        lineHeight: 1.6
    }
}

export const dataDisplayContainer = appStyles.mq({
    flex: ['1 1 auto', '1 1 auto', '1 1 auto', '0 1 420px'],
    'h3': {
        // color: appStyles.colors.orange,
        marginBottom: appStyles.sizes.small
    }
    
})

export const dataResultsContainer = appStyles.mq({
    flex: ['1 1 auto', '1 1 auto', '1 1 auto', '0 1 420px'],
    backgroundColor: appStyles.colors.background3,
    padding: appStyles.padding.medium,
    borderRadius: appStyles.sizes.small,
    boxShadow: "0.3em 0.3em 0.6em -0.4em " + appStyles.colors.darkgrey,
    'p': {
        marginBottom: 0
    },
    'h6': {
        textTransform: 'uppercase',
        marginBottom: appStyles.sizes.medium,
        fontWeight: 600
    },
    'h4': {
        fontWeight: 600,
        // textTransform: 'uppercase'
    }
})