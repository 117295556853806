import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dataElementText from '../constants/dataElementText'
import { dataDisplayContainer, dataResultsContainer } from '../styles';
import appStyles from '@appStyles';
import { FlexBox } from '@components/layout';

const DataElement = ({data, category, index}) => {
    console.log(data);
    console.log(category);
    const dataContent = useMemo(() => {
        let dataValue;
        dataValue = dataElementText.find(dataElement => (dataElement.label === data.label))

        return dataValue
    }, [])

console.log(dataContent);

  if (dataContent?.label) return (
    <FlexBox 
    direction='row' 
    align='center' 
    justify='space-evenly' 
    gap={appStyles.sizes.medium} 
    changeToColumn
    styles={{backgroundColor: index % 2 === 0 ? appStyles.colors.background3 :  appStyles.colors.background1, padding: ['12px', '30px']}}
    >

    <div css={dataDisplayContainer}>
        <h3>
            {dataContent.label}
        </h3>
        <p>
            {dataContent.description}
        </p>
    </div>
    <div css={[dataResultsContainer, {backgroundColor: appStyles.colors.background2 }]}>
        <h6>
            Ergebnis {dataContent.label} - {category}/4
        </h6>
        <h4>
            {dataContent[category].header}
        </h4>
        <p>
            {dataContent[category].text}
        </p>
    </div>
    </FlexBox>
  )
  return null;
}

DataElement.propTypes = {
    data: PropTypes.object.isRequired,
    category: PropTypes.number.isRequired
}

export default DataElement