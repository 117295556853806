import { gql } from "@apollo/client";

const get_reifegrade = gql`
    query Reifegrade {
        reifegrade(where: {abgeschlossen: true}) {
            publishedAt
            stage
            id
            email
            firma
            name
            abgeschlossen
            fuehrung_digi_01
            fuehrung_digi_02
            fuehrung_digi_03
            fuehrung_orku_01
            fuehrung_orku_02
            fuehrung_orku_03
            prozess_digi_01
            prozess_digi_02
            prozess_digi_03
            prozess_orku_01
            prozess_orku_02
            prozess_orku_03
            strategie_digi_01
            strategie_digi_02
            strategie_digi_03
            strategie_orku_01
            strategie_orku_02
            strategie_orku_03
            struktur_digi_01
            struktur_digi_02
            struktur_digi_03
            struktur_orku_01
            struktur_orku_02
            struktur_orku_03
            technologie_digi_01
            technologie_digi_02
            technologie_digi_03
            technologie_orku_01
            technologie_orku_02
            technologie_orku_03
            unternehmenskultur_digi_01
            unternehmenskultur_digi_02
            unternehmenskultur_digi_03
            unternehmenskultur_orku_01
            unternehmenskultur_orku_02
            unternehmenskultur_orku_03
        }
    }
`

export default get_reifegrade;