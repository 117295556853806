const dataElementText = [
    {
        label: 'Unternehmenskultur',
        description: 'Diese Kategorien bieten eine Möglichkeit, den Reifegrad einer Unternehmenskultur zu beurteilen und zu verstehen, wie gut die Organisation ihre Werte und Visionen kommuniziert und umsetzt, um eine positive Arbeitsumgebung zu schaffen.',
        1: {
            header: 'Anfangsstadium der Unternehmenskultur:',
            text: 'In diesem Stadium ist die Unternehmenskultur oft informell und nicht klar definiert. Es gibt möglicherweise keine einheitlichen Werte, Visionen oder Missionen, die von der Organisation unterstützt werden. Die Kommunikation kann unzureichend sein, und es kann eine begrenzte Transparenz geben, was zu Misstrauen und Unsicherheit führen kann. Mitarbeiter könnten sich nicht stark mit der Organisation identifizieren und es kann eine hohe Fluktuation geben.'
        },
        2: {

            header: 'Entwicklungsstadium der Unternehmenskultur:',
            text: 'In diesem Stadium beginnt die Organisation, ihre Werte, Visionen und Ziele zu definieren und zu kommunizieren. Es gibt Bemühungen, eine positive Arbeitsumgebung zu schaffen, in der Mitarbeiter sich geschätzt und unterstützt fühlen. Die Führungskräfte spielen eine wichtige Rolle bei der Förderung der Kultur und dem Vorleben der Werte. Es können erste Initiativen zur Mitarbeiterbindung und -entwicklung eingeführt werden, um die Kultur zu stärken.'
        },
        3: {

            header: 'Fortgeschrittenes Stadium der Unternehmenskultur:',
            text: 'In diesem Stadium ist die Unternehmenskultur gut etabliert und wird von allen Mitarbeitern aktiv gelebt. Die Werte und Visionen der Organisation sind klar definiert und werden in allen Aspekten des Geschäftsbetriebs berücksichtigt. Es gibt eine offene und transparente Kommunikation auf allen Ebenen der Organisation. Die Unternehmenskultur unterstützt die Mitarbeiter dabei, ihr volles Potenzial auszuschöpfen, und fördert Innovation, Zusammenarbeit und kontinuierliche Verbesserung.'
        },
        4: {
            header: 'Spitzenleistung in der Unternehmenskultur:',
            text: 'Organisationen, die Spitzenleistung in der Unternehmenskultur erreichen, haben eine Kultur, die sie zu einem attraktiven Arbeitgeber macht und sie von anderen abhebt. Die Kultur wird als differenzierender Wettbewerbsvorteil betrachtet und trägt maßgeblich zum langfristigen Erfolg der Organisation bei. Mitarbeiter identifizieren sich stark mit der Organisation und sind hochmotiviert, ihr Bestes zu geben. Die Unternehmenskultur unterstützt eine Atmosphäre von Vertrauen, Respekt und Offenheit, in der Innovation und Kreativität gedeihen können.'
        }
    },
    {
        label: 'Technologie',
        description: 'Diese Kategorien bieten eine Möglichkeit, den Reifegrad einer Organisation in Bezug auf ihre technologischen Fähigkeiten zu beurteilen und zu verstehen, wie gut sie Technologie einsetzt, um ihre Ziele zu erreichen und sich in einem zunehmend digitalen Umfeld zu behaupten.',
        1: {
            header: 'Anfangsstadium der Technologie-Nutzung:',
            text: 'In diesem Stadium nutzen Organisationen möglicherweise Technologie nur sehr begrenzt oder gar nicht. Es fehlen oft grundlegende digitale Infrastrukturen wie Computersysteme, Netzwerke oder Softwareanwendungen. Es besteht möglicherweise keine klare Strategie für den Einsatz von Technologie, und die Mitarbeiter verfügen möglicherweise nicht über die erforderlichen Fähigkeiten oder Schulungen, um Technologie effektiv zu nutzen.'
        },
        2: {

            header: 'Entwicklungsstadium der Technologie-Nutzung:',
            text: 'In diesem Stadium beginnen Organisationen, Technologie einzusetzen, um ihre Prozesse und Arbeitsabläufe zu verbessern. Es werden grundlegende Technologien wie Computersysteme, Softwareanwendungen und Netzwerke implementiert. Die Organisation investiert in Schulungen und Entwicklung, um sicherzustellen, dass die Mitarbeiter die Technologie effektiv nutzen können. Es gibt erste Ansätze zur Integration von Technologie in verschiedene Geschäftsbereiche, jedoch können noch ineffiziente Prozesse vorhanden sein.'
        },
        3: {

            header: 'Fortgeschrittenes Stadium der Technologie-Nutzung:',
            text: 'In diesem Stadium sind Organisationen technologisch fortgeschritten und nutzen eine breite Palette von Technologien, um ihre Ziele zu erreichen. Es gibt eine klare IT-Strategie, die eng mit den Geschäftszielen der Organisation verbunden ist. Die Organisation investiert in innovative Technologien wie künstliche Intelligenz, Big Data-Analyse und Cloud Computing, um Wettbewerbsvorteile zu erzielen. Die Integration von Technologie in alle Aspekte des Geschäftsbetriebs ist weit verbreitet, und die Mitarbeiter sind digital kompetent und können Technologie effektiv nutzen.'
        },
        4: {
            header: 'Spitzenleistung in der Technologie-Nutzung:',
            text: 'Organisationen, die Spitzenleistung in der Technologie erreichen, sind führend in ihrer Branche oder ihrem Sektor. Sie setzen innovative Technologien ein, um neue Geschäftsmodelle zu schaffen und sich an sich ändernde Marktbedingungen anzupassen. Die Organisation verfügt über eine agile und flexible IT-Infrastruktur, die es ihr ermöglicht, schnell auf Veränderungen zu reagieren und neue Chancen zu nutzen. Die technologische Führungskraft der Organisation wird durch kontinuierliche Innovation, Zusammenarbeit mit Partnern und ein starkes Engagement für die digitale Transformation unterstützt.'
        }
    },
    {
        label: 'Prozess',
        description: 'Diese Kategorien bieten eine Möglichkeit, den Reifegrad einer Organisation in Bezug auf ihre Prozesse zu beurteilen und zu verstehen, wie gut sie ihre operativen Abläufe und Arbeitsprozesse gestaltet und optimiert hat.',
        1: {
            header: 'Anfangsstadium des Prozessdenkens:',
            text: 'In diesem Stadium sind die Prozesse in der Organisation oft unstrukturiert und ad hoc. Es mangelt an klaren Richtlinien, Verfahrensanweisungen und Dokumentationen. Die Prozesse sind ineffizient und können zu Verzögerungen, Fehlern und Missverständnissen führen. Die Mitarbeiter haben möglicherweise unterschiedliche Arbeitsweisen, was zu Inkonsistenzen und Unvorhersehbarkeiten führt. '
        },
        2: {

            header: 'Entwicklungsstadium der Prozesse:',
            text: 'In diesem Stadium beginnt die Organisation, ihre Prozesse zu standardisieren und zu dokumentieren. Es werden klare Verfahrensanweisungen erstellt, um den Ablauf von Aufgaben und Aktivitäten zu definieren. Die Organisation identifiziert Engpässe und ineffiziente Bereiche und beginnt, Verbesserungsmaßnahmen zu implementieren. Es gibt einen Fokus auf die Schulung der Mitarbeiter, um sicherzustellen, dass sie die Prozesse verstehen und effektiv ausführen können.'
        },
        3: {

            header: 'Fortgeschrittenes Stadium der Prozessgestaltung:',
            text: 'In diesem Stadium sind die Prozesse gut etabliert und optimiert. Es gibt klare Verantwortlichkeiten, Durchlaufzeiten und Qualitätsstandards für jede Phase der Prozesse. Die Organisation nutzt Technologie und Automatisierung, um die Effizienz und Genauigkeit der Prozesse weiter zu verbessern. Es werden regelmäßige Audits und Überprüfungen durchgeführt, um sicherzustellen, dass die Prozesse weiterhin den Anforderungen entsprechen und verbessert werden können.'
        },
        4: {
            header: 'Spitzenleistung der Prozessgestaltung:',
            text: 'Organisationen, die Spitzenleistung in den Prozessen erreichen, zeichnen sich durch kontinuierliche Innovation und Exzellenz aus. Sie haben eine Kultur der kontinuierlichen Verbesserung, in der die Mitarbeiter aktiv dazu ermutigt werden, Vorschläge zur Optimierung der Prozesse einzureichen. Die Organisation ist äußerst agil und kann sich schnell an neue Anforderungen und Veränderungen anpassen, ohne die Qualität der Prozesse zu beeinträchtigen. Spitzenleistende Organisationen streben nach Best Practices und sind führend in ihrer Branche in Bezug auf Effizienz, Qualität und Kundenzufriedenheit.'
        }
    },
    {
        label: 'Strategie',
        description: 'Diese Kategorien bieten eine Möglichkeit, den Reifegrad einer Organisation in Bezug auf ihre strategischen Fähigkeiten zu beurteilen und zu verstehen, wie sie ihre Ziele und Visionen durch strategische Planung und Umsetzung erreichen kann.',
        1: {
            header: 'Anfangsstadium der strategischen Fähigkeiten:',
            text: 'In diesem Stadium haben Organisationen in der Regel noch keine klare Strategie entwickelt. Es gibt möglicherweise eine allgemeine Richtung oder Ziele, jedoch fehlen konkrete strategische Überlegungen, wie man diese Ziele erreichen will. Entscheidungen werden oft spontan und kurzfristig getroffen, ohne eine umfassende langfristige Vision.'
        },
        2: {

            header: 'Entwicklungsstadium strategischen Fähigkeiten:',
            text: 'In diesem Stadium beginnen Organisationen, eine Strategie zu entwickeln und zu implementieren. Es werden Ziele festgelegt, die auf einer gründlichen Analyse der internen und externen Umgebung basieren. Die Organisation identifiziert ihre Stärken, Schwächen, Chancen und Bedrohungen (SWOT-Analyse) und entwickelt Strategien, um diese zu nutzen oder zu minimieren. Es werden erste Maßnahmen ergriffen, um die strategischen Ziele zu erreichen, und es gibt einen Prozess zur Überwachung und Anpassung der Strategie im Zeitverlauf. '
        },
        3: {

            header: 'Fortgeschrittenes Stadium strategischen Vorgehens:',
            text: 'In diesem Stadium verfügen Organisationen über eine gut definierte und umfassende Strategie, die mit den langfristigen Zielen der Organisation übereinstimmt. Die Strategie wird regelmäßig überprüft und aktualisiert, um sicherzustellen, dass sie den sich ändernden Marktbedingungen und internen Anforderungen gerecht wird. Es gibt klare Leistungskennzahlen (KPIs), um den Fortschritt der Strategie zu messen, und es werden regelmäßige Bewertungen durchgeführt, um sicherzustellen, dass die Ziele erreicht werden. Die Organisation kann ihre Ressourcen effektiv einsetzen und hat die Fähigkeit, sich flexibel an Veränderungen anzupassen, ohne die langfristige Vision aus den Augen zu verlieren.'
        },
        4: {
            header: 'Spitzenleistung in der Strategie: ',
            text: 'Organisationen, die Spitzenleistung in der Strategie erreichen, sind visionär und innovativ. Sie sind in der Lage, Trends frühzeitig zu erkennen und proaktiv auf Veränderungen im Markt zu reagieren. Die Strategie ist eng mit der Unternehmenskultur verwoben und wird von allen Mitarbeitern verstanden und unterstützt. Spitzenleistende Organisationen setzen neue Standards in ihrer Branche und haben einen klaren Wettbewerbsvorteil durch ihre strategische Ausrichtung.'
        }
    },
    {
        label: 'Struktur',
        description: 'Die Kategorien für „Struktur“ bieten eine Möglichkeit, den Reifegrad einer Organisation zu beurteilen und zu verstehen, wie sie sich in Bezug auf Struktur, Prozesse, Kommunikation und Zielerreichung entwickelt hat. ',
        1: {
            header: 'Frühes Stadium einer Struktur: ',
            text: 'Organisationen im frühen Stadium zeigen sich unstrukturiert und es fehlt an klaren Prozessen, Richtlinien und Strategien. Die Kommunikation innerhalb der Organisation ist meist ineffektiv, was zu Missverständnissen und Fehlern führen kann. Die Ziele und Visionen der Organisation sind möglicherweise nicht klar definiert, und es gibt möglicherweise keine klare Ausrichtung auf die Mission der Organisation. '
        },
        2: {

            header: 'Entwicklungsstadium der Struktur: ',
            text: 'In diesem Stadium beginnen Organisationen, Strukturen aufzubauen und Prozesse zu implementieren. Es gibt eine verstärkte Fokussierung auf die Definition von Rollen und Verantwortlichkeiten innerhalb der Organisation. Die Kommunikation verbessert sich, und es werden Mechanismen eingeführt, um den Informationsfluss zu erleichtern. Die Organisation beginnt, ihre Ziele und Visionen klarer zu formulieren und Maßnahmen zu ergreifen, um diese Ziele zu erreichen.'
        },
        3: {

            header: 'Fortgeschrittenes Stadium der Struktur:',
            text: 'In diesem Stadium haben Organisationen effektive Strukturen und Prozesse etabliert, die reibungslose Arbeitsabläufe ermöglichen. Es besteht eine Kultur der kontinuierlichen Verbesserung, in der Innovation und Effizienz gefördert werden. Die Organisation verfügt über klare Strategien und Ziele, die von allen Mitarbeitern verstanden und unterstützt werden. Die Führungskräfte haben die Fähigkeit, sich flexibel an Veränderungen anzupassen und die Organisation erfolgreich durch verschiedene Herausforderungen zu führen.'
        },
        4: {
            header: 'Spitzenleistung der Struktur:',
            text: 'Organisationen, die Spitzenleistung erreichen, sind führend in ihrer Branche oder ihrem Bereich. Sie haben eine starke Marktposition, eine herausragende Reputation und eine hohe Kundenbindung. Die Organisation ist äußerst agil, innovativ und anpassungsfähig und kann schnell auf sich ändernde Marktbedingungen reagieren. Es herrscht eine Kultur der Exzellenz, in der Mitarbeiter kontinuierlich motiviert und herausgefordert werden, ihr Bestes zu geben, und in der Spitzenleistung als Standard angesehen wird.'
        }
    },
    {
        label: 'Führung',
        description: 'Diese Kategorien bieten eine Möglichkeit, die Entwicklung von Führungsfähigkeiten und -kompetenzen im Laufe der Zeit zu betrachten und zu verstehen, wie sich die Organisation als Ganzes in verschiedenen Stadien ihrer Führungsfähigkeiten entwickelt.',
        1: {
            header: 'Anfangsstadium der Führung:',
            text: 'In diesem Stadium zeigen die Führungspersonen im Unternehmen in der Regel wenig Erfahrung oder Verständnis für Führung. Sie könnten sich in erster Linie auf sich selbst konzentrieren und haben möglicherweise Schwierigkeiten, andere zu motivieren oder zu beeinflussen. Die Führungspersonen im Unternehmen können unsicher sein und benötigen möglicherweise Anleitung und klare Strukturen, um ihre Fähigkeiten zu entwickeln.'
        },
        2: {

            header: 'Entwicklungsstadium der Führung: ',
            text: 'In diesem Stadium beginnen die Führungspersonen im Unternehmen, grundlegende Führungsfähigkeiten zu entwickeln und zu verstehen. Sie können sich bewusster über die Bedürfnisse anderer werden und lernen, wie sie ein Team effektiv führen können. Die Führungspersonen in diesem Stadium könnten bereits erste Erfahrungen in der Führung gesammelt haben und beginnen, ihre Fähigkeiten weiter zu entwickeln, indem sie Feedback annehmen und sich selbst verbessern. '
        },
        3: {

            header: 'Fortgeschrittenes Stadium der Führung:',
            text: 'Die Führungspersonen im Unternehmen haben bereits beträchtliche Erfahrung in der Führung gesammelt und verfügen über ein solides Verständnis für die Prinzipien der Führung. Sie können komplexe Probleme angehen, Teams effektiv motivieren und inspirieren und haben ein starkes Bewusstsein für die Bedeutung von Kommunikation und Empathie in der Führung. Fortgeschrittene Führungskräfte sind in der Lage, ihre Teams erfolgreich durch Herausforderungen zu führen und gleichzeitig eine positive Unternehmenskultur zu fördern.'
        },
        4: {
            header: 'Spitzenleistung der Struktur:',
            text: 'Führungspersonen, die Spitzenleistungen in der Führung erreichen, gelten als herausragende Führungskräfte und Vorbilder. Sie haben nicht nur ausgezeichnete Führungsfähigkeiten, sondern sind auch visionär, innovativ und können andere dazu inspirieren, ihr Bestes zu geben. Spitzenleistende Führungskräfte haben oft einen tiefgreifenden Einfluss auf Organisationen und Gesellschaften und hinterlassen ein erhebliches Erbe in ihrer Führungsrolle. Diese Kategorien bieten eine Möglichkeit, die Entwicklung von Führungsfähigkeiten und -kompetenzen im Laufe der Zeit zu betrachten und zu verstehen, wie sich Personen in verschiedenen Stadien ihrer Führungskarriere befinden können.'
        }
    },

   
]

export default dataElementText;