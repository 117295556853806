import React, { useMemo } from 'react'
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ReferenceLine, LabelList, Label, ResponsiveContainer } from 'recharts';
import { labelContainer } from './styles';
import dataArray from './constants/dataArray';
import { FlexBox } from '@components/layout';
import appStyles from '@appStyles';
import DataElement from './components/DataElement';

const DisplayReifegradResultsChart = ({testResults}) => {
    const chartData =  useMemo(() =>{
        const chartDataArray = []

        dataArray.forEach(dataElement => {
            let x = testResults.formDataTest.find(data=> data.pageTitle === dataElement.pageTitle)?.questionsPerPage[0]?.value;
            let y = testResults.formDataTest.find(data=> data.pageTitle === dataElement.pageTitle)?.questionsPerPage[1]?.value;
            let label = dataElement.pageTitle
            let color = testResults.formDataTest.find(data=> data.pageTitle === dataElement.pageTitle)?.color;
            if (x, y, label) {
                chartDataArray.push({x,y,label, color})
            }
        })
        return chartDataArray;

    }, [])

    const tooltipContent = e => {
        if (e.payload.length > 0 ){
            let label = '';
            let x = ''
            let y = ''
            e.payload.forEach(payload => {
                if (payload.dataKey === 'x') {
                    x= `${payload.name}: ${payload.value}`
                }
                if (payload.dataKey === 'y') {
                    y= `${payload.name}: ${payload.value}`
                }
                label =  payload.payload.label
            })
            return <div css={labelContainer}>
                    <h6>{label}</h6>
                <p>
                {x}<br />
                {y}</p>
            </div> 
        }
        return null;
     };

     const getCategoryScale = (data) => {
        if (data.x < 15 && data.y < 15) {
            return 1
        }
        if (data.x >= 15 && data.y < 15) {
            return 2
        }
        if (data.x < 15 && data.y >= 15) {
            return 3
        }
        if (data.x >= 15 && data.y >= 15) {
            return 4
        }
        return 1
     }

  return (
    <div>  
        <h2>
            Ihre Ergebnisse
        </h2>
        <h3>
            Grafischer Überblick
        </h3>
        <ResponsiveContainer width='100%' height='100%' minHeight='400px'>
            <ScatterChart
            width={400}
            height={400}
            margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 0,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" horizOriginX={0} horizAdvX={5}  />
            <XAxis
                type="number"
                dataKey="x"
                domain={[0, 30]}
                name="Organisation & Kultur"
                unit=""
                ticks={[0, 5,10,15,20,25,30]}
                label={{
                    key: 'xAxisLabel',
                    value: 'Organisation & Kultur',
                    position: 'bottom',
                }}
            />
            <YAxis
                type="number"
                ticks={[0, 5,10,15,20,25,30]}
                dataKey="y"
                domain={[0,30]}
                name="Digitalisierung"
                unit=""
                label={{
                    value: `Digitalisierung`,
                    style: { textAnchor: 'middle' },
                    angle: -90,
                    position: 'left',
                    offset: -15,
                }}
                // allowDataOverflow={false}
            />
            <ReferenceLine
                    x={15}
                    stroke="orange"
                    strokeDasharray="3 3"
                    strokeWidth={1.5}
                    strokeOpacity={0.65}
                />
            <ReferenceLine
                    y={15}
                    stroke="orange"
                    strokeDasharray="3 3"
                    strokeWidth={1.5}
                    strokeOpacity={0.65}
                />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} label='label' labelFormatter={() => 'label'} content={tooltipContent} />
            <Scatter name="A school" data={chartData} fill="#8884d8">
                {chartData.map((entry, index) => (
                    <>
                        <Cell key={`cell-${entry.label}`} fill={entry.color} cursor={'pointer'} />
                        <LabelList dataKey="label" position='bottom' width={200} />
                    </>
                ))}
            </Scatter>
            </ScatterChart>
        </ResponsiveContainer>
    
        <div css={{marginTop: appStyles.sizes.extraLarge}}>
            <h2>
                Die Einzelergebnisse im Überblick
            </h2>
    
            <FlexBox direction='column' align='stretch' justify='space-between' >
                {chartData.map((entry, index) => <DataElement key={entry.label} data={entry} category={getCategoryScale(entry)} index={index} /> )}
            </FlexBox>
        </div>
    </div>
  )
}

export default DisplayReifegradResultsChart